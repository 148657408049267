import React, { useState, MouseEvent, useEffect, Suspense } from 'react';
import cc from 'classcat';
import C from './Header.css';
import BulbIcon from './light-bulb.svg';
import LLMIcon from './llm.svg';
import MegaphoneIcon from './megaphone.svg';
import CircledHeartIcon from './circled-heart.svg';
import MenuIcon from '!!react-svg-loader!./menu.svg';
import CloseIcon from '@mui/icons-material/Close';
import PlayIcon from './play-2.svg';
import AppLogoDropdown from './AppLogoDropdown';
import Box from '@mui/material/Box';
import { introPostUrl, twitterUrl, llmPostUrl } from './external-links';
import { Trans } from '@lingui/macro';
import { LocaleSwitcherIcon } from 'components/locale-switcher/LocaleSwitcher';
import { Stack } from '@mui/material';
import { useWindowSize } from 'rooks';
import { useModal } from 'mui-modal-provider';
import AccessDeniedModal from 'components/access-denied-modal/access-denied-modal';

const Drawer = React.lazy(() => import('@mui/material/Drawer'));

export const smoothScrollTo = (selector: string, delay = false) => {
  const initScroll = () => {
    document.querySelector(selector)?.scrollIntoView({
      behavior: 'smooth',
    });
  };
  return (e: MouseEvent) => {
    e.preventDefault();
    if (delay) {
      setTimeout(initScroll, 500);
    } else initScroll();
  };
};

export default function Header(p: {
  setShowingVideoOverlay: (input: boolean) => void;
}) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isNarrow, setNarrow] = useState(false);

  const { innerWidth } = useWindowSize();

  useEffect(() => {
    if (!innerWidth) return;
    if (innerWidth < 1000) {
      setNarrow(true);
    } else {
      setNarrow(false);
    }
  }, [innerWidth]);

  return (
    <header className={C.header}>
      <AppLogoDropdown />
      <div className={C.headerSpacer} />
      <div className={C.narrowMenu}>
        <a className={C.hamMenuTrigger}>
          <MenuIcon
            onClick={() => {
              setDrawerOpen((it) => !it);
            }}
          />
        </a>
      </div>
      {isNarrow && (
        <Suspense fallback={null}>
          <Drawer
            anchor='right'
            open={drawerOpen}
            onClose={() => {
              setDrawerOpen(false);
            }}
            className={C.drawer}
          >
            <div className={C.menuRow}>
              <a className={C.hamMenuTrigger}>
                <CloseIcon
                  style={{
                    width: '1rem',
                    color: 'white',
                  }}
                  onClick={() => {
                    setDrawerOpen((it) => !it);
                  }}
                />
              </a>
            </div>
            <Box style={{ width: window.innerWidth * 0.9 }} role='presentation'>
              <HeaderLinks
                {...{
                  setDrawerOpen,
                  setShowingVideoOverlay: p.setShowingVideoOverlay,
                }}
                delayScroll={true}
              />
            </Box>
          </Drawer>
        </Suspense>
      )}
      <HeaderLinks
        className={C.primary}
        setShowingVideoOverlay={p.setShowingVideoOverlay}
        isNarrow={isNarrow}
      />
    </header>
  );
}

const HeaderLinks = (p: {
  className?: string;
  delayScroll?: boolean;
  setShowingVideoOverlay?: (isOpen: boolean) => void;
  setDrawerOpen?: (isOpen: boolean) => void;
  isNarrow?: boolean;
}) => {
  const { showModal } = useModal();

  return (
    <ul
      className={cc([p.className, C.headerLinks])}
      onClick={(e) => {
        const tag: string = (e.target as HTMLElement)?.tagName?.toLowerCase();
        if (tag === 'a' || tag === 'button') {
          p.setDrawerOpen?.(false);
        }
      }}
    >
      <li>
        <a href={llmPostUrl} target='_blank' rel='noreferrer noopener'>
          <img src={LLMIcon} className={C.headerIcon} />
          AI Education
        </a>
      </li>
      <li>
        <a href={introPostUrl} target='_blank' rel='noreferrer noopener'>
          <img src={BulbIcon} className={C.headerIcon} />
          <Trans>Ideas & Values</Trans>
        </a>
      </li>
      <li>
        <a onClick={() => p.setShowingVideoOverlay?.(true)}>
          <img src={PlayIcon} className={C.headerIcon} />
          <Trans>Video</Trans>
        </a>
      </li>
      <li>
        <a
          href='#press'
          onClick={smoothScrollTo('#press-banner', !!p.delayScroll)}
        >
          <img src={MegaphoneIcon} className={C.headerIcon} />
          <Trans>In the News</Trans>
        </a>
      </li>
      <li>
        <a
          href='#support'
          onClick={smoothScrollTo('#support-banner', !!p.delayScroll)}
        >
          <img src={CircledHeartIcon} className={C.headerIcon} />
          <Trans>Connect</Trans>
        </a>
      </li>
      <li>
        <Stack
          className={cc([C.rightLinks])}
          direction={p.isNarrow ? 'column' : 'row'}
          alignItems='flex-start'
          gap={1}
        >
          <a href='/app/signin' className={cc([C.btn])}>
            <Trans>Sign in</Trans>
          </a>
          <a
            onClick={() => {
              const modal = showModal(AccessDeniedModal, {
                onClose: () => {
                  modal.destroy();
                },
                email: '',
                title: <Trans>Join the Invite List</Trans>,
              });
            }}
            className={cc([C.primaryHeaderCTA, C.btn])}
          >
            <Trans>Sign up</Trans>
          </a>
          <Box>
            <LocaleSwitcherIcon />
          </Box>
        </Stack>
      </li>
    </ul>
  );
};
