import type { UserType } from "@cocoplatform/coco-rtc-shared"

export const Types = {
    ADD_COLLABORATOR: "scratch-gui/collaborators/ADD_COLLABORATOR",
    REMOVE_COLLABORATOR: "scratch-gui/collaborators/REMOVE_COLLABORATOR",
    FLAG_COLLABORATOR: "scratch-gui/collaborators/FLAG_COLLABORATOR",
    PIN_COLLABORATOR: "scratch-gui/collaborators/PIN_COLLABORATOR",
    UNPIN_COLLABORATOR: "scratch-gui/collaborators/UNPIN_COLLABORATOR",
    VIEW_COLLABORATOR: "scratch-gui/collaborators/VIEW_COLLABORATOR",
    SET_IMAGE: "scratch-gui/collaborators/SET_IMAGE",
    SET_VIDEO_TRACK: "scratch-gui/collaborators/SET_VIDEO_TRACK",
    SET_AUDIO_TRACK: "scratch-gui/collaborators/SET_AUDIO_TRACK",
    UPDATE_COLLABORATOR_STATUS:
        "scratch-gui/collaborators/UPDATE_COLLABORATOR_STATUS",
    SET_VIDEO_TRACK_STATUS: "scratch-gui/collaborators/SET_VIDEO_TRACK_STATUS",
    SET_AUDIO_TRACK_STATUS: "scratch-gui/collaborators/SET_AUDIO_TRACK_STATUS",

    VIEWING_PROJECT_RELOADABLE:
        "scratch-gui/collaborators/VIEWING_PROJECT_RELOADABLE",
    SET_COLLABORATOR_ID: "scratch-gui/collaborators/SET_COLLABORATOR_ID",
    SET_USER_NAME: "scratch-gui/collaborators/SET_USER_NAME",
    SET_USER_TYPE: "scratch-gui/collaborators/SET_USER_TYPE",
    SET_VISITED: "scratch-gui/collaborators/SET_VISITED",
    UPDATE_COLLABORATOR_CHUNKS:
        "scratch-gui/collaborators/UPDATE_COLLABORATOR_CHUNKS",
    UPDATE_COLLABORATOR_CHUNK:
        "scratch-gui/collaborators/UPDATE_COLLABORATOR_CHUNK",
    ADD_DISPLAY_BROADCAST: "scratch-gui/collaborators/ADD_DISPLAY_BROADCAST",
    REMOVE_DISPLAY_BROADCAST:
        "scratch-gui/collaborators/REMOVE_DISPLAY_BROADCAST",
    UPDATE_PROJECT_LOAD_STATUS:
        "scratch-gui/collaborators/UPDATE_PROJECT_LOAD_STATUS",
    UPDATE_PROJECT_LOAD_COMPLETE:
        "scratch-gui/collaborators/UPDATE_PROJECT_LOAD_COMPLETE",
    REMOTE_MUTE_COLLABORATOR:
        "scratch-gui/collaborators/REMOTE_MUTE_COLLABORATOR",
    ACTIVATE_TAB: "scratch-gui/navigation/ACTIVATE_TAB",
    SWITCHING_PROJECT: "scratch-gui/collaborators/SWITCHING_PROJECT",
}

export type ActionType = keyof typeof Types

export const activateTab = function (tab: number) {
    return {
        type: Types.ACTIVATE_TAB,
        activeTabIndex: tab
    };
};

export type ActivateTabAction = ReturnType<
    typeof activateTab
>


export const addCollaborator = (opts: {
    id: string
    name?: string
    userType: UserType
    isLive: boolean
    isReal: boolean
    personaId?: string
    originalCreator?: string
}) => ({
    type: Types.ADD_COLLABORATOR,
    ...opts,
})

export type AddCollaboratorAction = ReturnType<typeof addCollaborator>

export const switchingProject = (value: string | null, reload = false) => ({
    type: Types.SWITCHING_PROJECT,
    value,
    reload,
})

export type SwitchingProjectAction = ReturnType<typeof switchingProject>

export const setCollaboratorId = (id: string) => ({
    type: Types.SET_COLLABORATOR_ID,
    id,
})

export const updateCollaboratorChunks = () => ({
    type: Types.UPDATE_COLLABORATOR_CHUNKS,
})

export type UpdateCollaboratorChunksAction = ReturnType<
    typeof updateCollaboratorChunks
>

export const updateCollaboratorChunk = (chunk: string[], idx: number) => ({
    type: Types.UPDATE_COLLABORATOR_CHUNK,
    chunk,
    idx,
})

export type UpdateCollaboratorChunkAction = ReturnType<
    typeof updateCollaboratorChunk
>
export const removeCollaborator = (id: string) => ({
    type: Types.REMOVE_COLLABORATOR,
    id: id,
})

export type RemoveCollaboratorAction = ReturnType<typeof removeCollaborator>

export const flagCollaborator = (id: string | string[]) => ({
    type: Types.FLAG_COLLABORATOR,
    id: id,
})

export type FlagCollaboratorAction = ReturnType<typeof flagCollaborator>

export const pinCollaborator = (id: string) => ({
    type: Types.PIN_COLLABORATOR,
    id: id,
})

export type PinCollaboratorAction = ReturnType<typeof pinCollaborator>

export const unpinCollaborator = (id: string) => ({
    type: Types.UNPIN_COLLABORATOR,
    id: id,
})

export type UnPinCollaboratorAction = ReturnType<typeof unpinCollaborator>

export const viewCollaborator = (id: string) => ({
    type: Types.VIEW_COLLABORATOR,
    id: id,
})

export type ViewCollaboratorAction = ReturnType<typeof viewCollaborator>

export const setImage = (id: string, image: string) => ({
    type: Types.SET_IMAGE,
    id: id,
    image: image,
})

export type SetImageAction = ReturnType<typeof setImage>

export const setAudioTrack = (id: string, track?: MediaStreamTrack | null) => ({
    type: Types.SET_AUDIO_TRACK,
    id,
    track,
})

export type SetAudioTrackAction = ReturnType<typeof setAudioTrack>

export const setVideoTrack = (id: string, track?: MediaStreamTrack | null) => ({
    type: Types.SET_VIDEO_TRACK,
    id,
    track,
})

export type SetVideoTrackAction = ReturnType<typeof setVideoTrack>

export const setAudioTrackStatus = (id: string, isStable: boolean) => ({
    type: Types.SET_AUDIO_TRACK_STATUS,
    id,
    isStable,
})

export type SetAudioTrackStatusAction = ReturnType<typeof setAudioTrackStatus>

export const setVideoTrackStatus = (id: string, isStable: boolean) => ({
    type: Types.SET_VIDEO_TRACK_STATUS,
    id,
    isStable,
})

export type SetVideoTrackStatusAction = ReturnType<typeof setVideoTrackStatus>

export const viewingProjectReloadable = (value: boolean) => ({
    type: Types.VIEWING_PROJECT_RELOADABLE,
    reloadable: value,
})

export type ViewingProjectReloadableAction = ReturnType<
    typeof viewingProjectReloadable
>

export const setUserType = (userType: string) => ({
    type: Types.SET_USER_TYPE,
    userType,
})

export const setUserName = (userName: string, id?: string) => ({
    type: Types.SET_USER_NAME,
    userName,
    id,
})

export type SetUserTypeAction = ReturnType<typeof setUserType>

export const setVisited = () => ({
    type: Types.SET_VISITED,
})

export type SetVisitedAction = ReturnType<typeof setVisited>

export interface CollaboratorStatusUpdateParams {
    trackId: string
    collaboratorId: string
}

export const updateCollaboratorStatus = (
    status: string,
    params: Partial<CollaboratorStatusUpdateParams> = {}
) => ({
    ...params,
    status,
    type: Types.UPDATE_COLLABORATOR_STATUS,
})

export type UpdateCollaboratorStatusAction = ReturnType<
    typeof updateCollaboratorStatus
>

export const addDisplayBroadcast = function (
    id: string,
    track?: MediaStreamTrack | null
) {
    return { type: Types.ADD_DISPLAY_BROADCAST, id, track }
}

export type AddDisplayBroadcastAction = ReturnType<typeof addDisplayBroadcast>

export const removeDisplayBroadcast = function (id: string) {
    return { type: Types.REMOVE_DISPLAY_BROADCAST, id }
}

export type RemoveDisplayBroadcastAction = ReturnType<
    typeof removeDisplayBroadcast
>

export const updateProjectLoadStatus = (id: string, isLoading: boolean) => ({
    id,
    isLoading,
    type: Types.UPDATE_PROJECT_LOAD_STATUS,
})

export type UpdateProjectLoadStatusAction = ReturnType<
    typeof updateProjectLoadStatus
>

export const remoteMuteCollaborator = () => ({
    type: Types.REMOTE_MUTE_COLLABORATOR,
})

export type RemoteMutateCollaboratorAction = ReturnType<
    typeof remoteMuteCollaborator
>

export type CollaboratorAction =
    | AddCollaboratorAction
    | RemoveCollaboratorAction
    | ViewCollaboratorAction
    | SetImageAction
    | SetAudioTrackAction
    | SetVideoTrackAction
    | UpdateCollaboratorChunksAction
    | UpdateCollaboratorChunkAction
    | SetAudioTrackStatusAction
    | SetVideoTrackStatusAction
    | ViewingProjectReloadableAction
    | SetUserTypeAction
    | SetVisitedAction
    | UpdateCollaboratorStatusAction
    | AddDisplayBroadcastAction
    | RemoveDisplayBroadcastAction
    | UpdateProjectLoadStatusAction
    | FlagCollaboratorAction
    | PinCollaboratorAction
    | UnPinCollaboratorAction
    | RemoteMutateCollaboratorAction
    | ActivateTabAction
    | SwitchingProjectAction

export const collaboratorActionCreators = {
    addCollaborator,
    removeCollaborator,
    viewCollaborator,
    setImage,
    setAudioTrack,
    setVideoTrack,
    updateCollaboratorStatus,
    setVideoTrackStatus,
    setAudioTrackStatus,
    viewingProjectReloadable,
    setUserName,
    setUserType,
    setVisited,
    updateCollaboratorChunks,
    updateCollaboratorChunk,
    addDisplayBroadcast,
    removeDisplayBroadcast,
    updateProjectLoadStatus,
    setCollaboratorId,
    flagCollaborator,
    pinCollaborator,
    unpinCollaborator,
    remoteMuteCollaborator,
    activateTab,
    switchingProject,
}
