import * as z from "zod"
import { FileUploadSchema } from "../../utils/file-upload"
import { strNum, optFlag } from "../../utils/zod"
import { UserSchema } from "../users/types"
import { WorldCorridorSchema } from "../world-corridor/types"

export const SpaceThemeCategorySchema = z.object({
    themeId: z.string().nullish(),
    categoryId: z.string().nullish(),
})

export type SpaceThemeCategory = z.TypeOf<typeof SpaceThemeCategorySchema>

export const SpaceUserSchema = z.object({
    userId: z.string(),
    displayName: z.string().nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    loginAllowance: z.string().nullish()
})

export const SpaceSchema = z.object({
    id: z.string(),
    genericInvitationCode: z.string().nullish(),
    title: z.string().nullish(),
    description: z.string().nullish(),
    themes: z.string().array().nullish(),
    createdAt: z.number().nullish(),
    startedAt: z.number().nullish(),
    expectedToStartAt: z.number().nullish(),
    expectedToEndAt: z.number().nullish(),
    endedAt: z.number().nullish(),
    archivedAt: z.number().nullish(),
    participationLockedAt: z.number().nullish(),
    bannerUrl: z.string().nullish(),
    spotCount: z.number().nullish(),

    // TODO Make following properties non null
    likeCount: z.number().nullish(),
    participantCount: z.number().nullish(),
    participants: z.object({
        username: z.string().nullish(),
        userId: z.string().nullish(),
        firstName: z.string().nullish(),
        lastName: z.string().nullish(),
    }).array().nullish(),
    bookmarkCount: z.number().nullish(),
    isBookmarked: z.boolean().nullish(),
    isHappening: z.boolean().nullish(),
    hosts: UserSchema.array().nullish(),
    spaceType: z.string().nullish(),
    communityId: z.string().nullish(),
    awaitHost: z.boolean().nullish(),
    collaboratorCount: z.number().nullish(),

    participationPolicy: z.string().nullish(),
    micEnabled: z.boolean().nullish(),
    videoEnabled: z.boolean().nullish(),
    isHost: z.boolean().optional(),
    isCollaborator: z.boolean().optional(),
    didParticipate: z.boolean().optional(),
    meetingMode: z.string().nullish(),
    userName: z.string().nullish(),
    selectedUserIds: z.string().array().nullish(),
    
    canParticipate: z.boolean(),
    

    // Jam specific fields
    jamVideoUrl: z.string().nullish(),
    jamVideoLength: z.number().nullish(),

    // Remix metadata
    isRemix: z.boolean().nullish(),
    didCompleteRemix: z.boolean().nullish(),
})

export const ExtendedSpace = SpaceSchema.merge(WorldCorridorSchema);

export type Space = z.TypeOf<typeof ExtendedSpace>

export const SpaceQueryResultSchema = z.object({
    spaces: ExtendedSpace.array(),
    count: z.number().optional(),
})

export type SpaceQueryResult = z.TypeOf<typeof SpaceQueryResultSchema>

export const SpaceQueryInputSchema = z.object({
    includeCollaborators: optFlag,
    limit: strNum.nullish().transform((it) => it ?? 10),
    offset: strNum.nullish().transform((it) => it ?? 0),
    userId: z.string(),
    sortBy: z.enum([
        "expectedToStartAt",
        "bookmarkCount",
        "lastVisitedAt",
        "startedAt",
        "dailyVisitCount",
        "endedAt",
        "lastActivity",
    ]),
    isUpcoming: optFlag,
    isEnded: optFlag,
    isParticipating: optFlag,
    isBookmarked: optFlag,
    isHosting: optFlag,
    isInvited: optFlag,
    isActive: optFlag,
    isNewSpaceToJoin: optFlag,
    isMySpace: optFlag,
    isJam: optFlag,
    isSpaceToRemix: optFlag,
    seed: z.number().nullish(),
    spacesByOthers: optFlag,
    participantId: z.string().nullish(),
    query: z.string().nullish(),
    themeIds: z.string().array().nullish(),
    isApproved: z.boolean().nullish(),
    featured: z.boolean().nullish(),
    rating: strNum.nullish(),
    participantCount: z.string().nullish(),

    communityId: z.string().nullish(),
    rangeStart: strNum.nullish(),
    rangeEnd: strNum.nullish(),
    sortDir: z
        .enum(["asc", "desc"])
        .nullish()
        .transform((it) => it ?? "desc"),
})

export type SpaceQueryInput = z.TypeOf<typeof SpaceQueryInputSchema>

export type SpaceParticipationPolicy = z.TypeOf<
    typeof SpaceParticipationPolicySchema
>

export const SpaceParticipationPolicySchema = z.enum([
    "open",
    "invite_only",
    "closed",
])

export const SpaceInputSchema = z.object({
    userId: z.string(),
    spaceId: z.string().nullish(),
    postClone: z.string().optional(),
    input: z.object({
        title: z.string(),
        postClone: z.coerce.boolean().nullish(),
        description: z.string().nullish(),
        communityId: z.string().nullish(),
        expectedToStartAt: z.number().nullish(),
        expectedToEndAt: z.number().nullish(),
        micEnabledForHost: z.boolean().nullish(),
        vidEnabledForHost: z.boolean().nullish(),
        micEnabledForParticipants: z.boolean().nullish(),
        vidEnabledForParticipants: z.boolean().nullish(),
        banner: FileUploadSchema.nullish(),
        userIds: z.string().array().nullish(),
        spaceType: z.string().nullish(),
        meetingMode: z.string().nullish(),
        themes: z.string().array().nullish(),
        awaitHost: z.boolean().nullish(),
        selectedUserIds: z.string().array().nullish(),
        inviteOnly: z.boolean().nullish(),
    }),
})

export type SpaceInput = z.TypeOf<typeof SpaceInputSchema>

export const SpaceProjectSnapshotSchema = z.object({
    projectId: z.string(),
    snapshotDataUrl: z.string(),
})

export type SpaceProjectSnapshot = z.TypeOf<typeof SpaceProjectSnapshotSchema>

export const SpaceConcludeInputSchema = z.object({
    userId: z.string(),
    spaceId: z.string(),
    end: z.boolean().nullish(),
    lock: z.boolean().nullish(),
    archive: z.boolean().nullish(),
    del: z.boolean().nullish(),
})

export type SpaceConcludeInput = z.TypeOf<typeof SpaceConcludeInputSchema>

export const SpaceProjectInputSchema = z.object({
    userId: z.string(),
    spaceId: z.string(),
    userName: z.string(),
})

export type SpaceProjectInput = z.TypeOf<typeof SpaceProjectInputSchema>

export const SpaceCloneInputSchema = z.object({
    communityId: z.string(),
    spaceId: z.string(),
    userId: z.string(),
    action: z.enum(["remix", "duplicate"]),
})

export type SpaceCloneInput = z.TypeOf<typeof SpaceCloneInputSchema>

export const SpaceCloneOutputSchema = z.object({
    spaceId: z.string(),
})

export const AdhocSpaceCreationPolicySchema = z.enum([
    "multi_user",
    "single_user",
    "multi_user_av_restricted",
    "single_user_av_restricted"
])

export type AdhocSpaceCreationPolicy = z.TypeOf<
    typeof AdhocSpaceCreationPolicySchema
>

export type SpaceParticipantType = z.TypeOf<typeof SpaceParticipantTypeSchema>

export const SpaceParticipantTypeSchema = z.enum([
    "CREATOR",
    "VIEWER",
    "TEMPLATE",
])


export const SpaceTypeSchema = z.enum(["scratch_blocks", "p5_text", "canvas"])

export type SpaceType = z.TypeOf<typeof SpaceTypeSchema>

