import PreLoginNavBar from 'components/prelogin-nav-bar/PreLoginNavBar';
import React, { useEffect, useMemo } from 'react';
import UserMenu from 'components/user-menu/UserMenu';
import NotificationTrigger from 'components/notification-menu/NotificationTrigger';
import { useUserProfile } from 'atoms/user-profile';
import Stack from '@mui/material/Stack';
import C from './PostLoginNavBar.css';
import { Link, useHistory, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LightTooltip } from '../min-sidebar-community-list/MinSidebarCommunityList';
import OpenLinkIcon from '!!react-svg-loader!./icons/icon--open-link.svg';
import PrimarySpaceSearchInput from '../primary-space-search-input/PrimarySpaceSearchInput';
import { useCommunitySummaries } from '../../atoms/community-summaries';
import { CommunityWithAggregates } from '@cocoplatform/coco-rtc-shared';
import * as CMR from 'constants/community-roles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NestedMenuItem from '../nested-nav-menu/NestedMenuItem';
import Divider from '@mui/material/Divider';
import cc from 'classcat';
import { useWindowSize } from 'rooks';
import ListItemText from '@mui/material/ListItemText';
import LocaleSwitcher, {
  LocaleSwitcherIcon,
} from 'components/locale-switcher/LocaleSwitcher';
import { Trans, msg } from '@lingui/macro';
import _ from 'lodash';
import { useLingui } from '@lingui/react';
import ROUTES, { DashboardParams } from 'constants/routes';
import AddIcon from '@mui/icons-material/Add';
import { useAuthState } from 'utils/auth-state';
import { useFlags } from 'flagsmith/react';
import FeedbackButton from 'components/feedback-button/FeedbackButton';
import { ListItemIcon } from '@mui/material';
import FacebookIcon from '@mui/icons-material/FacebookOutlined';

const selectHostableCommunities = (communities: CommunityWithAggregates[]) => {
  return communities.filter((it) => {
    return it.isActive && CMR.isGTE(it.currentUserRoles, 'MEMBER');
  });
};

export default function PostLoginNavBar(p: {
  borderDist?: number;
  rightControls?: React.ReactChild | null;
  centerControls?: React.ReactChild | null;
  communityId?: string;
}) {
  const { _ } = useLingui();
  const { innerWidth } = useWindowSize();
  const history = useHistory();
  const { userProfile, canCreateCommunity } = useUserProfile();
  const [communitiesAnchorEl, setCommunitiesAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [navAnchorEl, setNavAnchorEl] = React.useState<null | HTMLElement>(
    null,
  );

  const {
    communities,
    syncState: summarySyncState,
    trigger,
  } = useCommunitySummaries({
    autoLoad: false,
  });

  const { communityId } = useParams<DashboardParams>();

  const { isSignedIn, profileCompletionState } = useAuthState();

  const communityMenuOpen = Boolean(communitiesAnchorEl);
  const navMenuOpen = Boolean(navAnchorEl);

  const width = innerWidth || 1280;

  const isTablet = width <= 1280;
  const isMobile = width <= 640;

  const onboardingComplete = userProfile?.onboardingComplete;
  const profileComplete =
    profileCompletionState === 'COMPLETE' ||
    profileCompletionState === 'PERMITTED_INCOMPLETE';

  const { world_corridor_page } = useFlags(['world_corridor_page']);

  const isWorldCorridorVisible =
    !isTablet &&
    onboardingComplete &&
    profileComplete &&
    world_corridor_page?.enabled;
  // const isWorldCorridorVisible = false;
  /*
  const isEducatorCommunityVisible =
   width > 1080 && onboardingComplete && profileComplete;
  */

  const community =
    communityId && communityId !== '$all'
      ? communities.find((c) => c.id === communityId)
      : communities[0];
  const canManageUsers =
    community?.currentUserRoles?.includes('OWNER') ||
    community?.currentUserRoles?.includes('MANAGER');
  const isMyCorridorsVisible =
    width > 864 && onboardingComplete && profileComplete;

  const isEducatorHubVisible =
    (userProfile?.educationalAccessApproved ||
      userProfile?.userType === 'EDUCATIONAL') &&
    canManageUsers &&
    community?.corridorType === 'EDUCATIONAL';
  const isAboutVisible = onboardingComplete && profileComplete;
  const isNotificationVisible =
    !isMobile && onboardingComplete && profileComplete;
  const isLocaleVisible = true;
  const isProfileVisible = true;

  const searchVisible = onboardingComplete && profileComplete;

  useEffect(() => {
    if (summarySyncState === 'pending') {
      trigger();
    }
  }, []);

  const hostableCommunities = useMemo(() => {
    return selectHostableCommunities(communities);
  }, [communities]);

  return (
    <PreLoginNavBar
      leftControls={
        <Stack
          direction='row'
          alignItems='center'
          className={cc([C.navLinkRow, isTablet ? C.lessMargin : ''])}
          sx={{ flexGrow: 1, width: '100%' }}
        >
          {isTablet && onboardingComplete ? (
            <>
              <IconButton
                id='nav-menu-toggle'
                aria-controls={navMenuOpen ? 'nav-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={navMenuOpen ? 'true' : undefined}
                onClick={(e) => setNavAnchorEl(e.currentTarget)}
                className={C.navLink}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='nav-menu'
                anchorEl={navAnchorEl}
                open={navMenuOpen}
                onClose={() => setNavAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {isEducatorHubVisible && (
                  <MenuItem>
                    <a
                      href='https://www.facebook.com/groups/cocobuild'
                      className={cc([C.navLink, C.smallScreen])}
                      target='_blank'
                    >
                      <ListItemIcon>
                        <FacebookIcon />
                      </ListItemIcon>
                      <ListItemText>
                        <Trans>Educator Hub</Trans>
                      </ListItemText>
                    </a>
                  </MenuItem>
                )}
                {isAboutVisible && (
                  <MenuItem>
                    <Link
                      to='/'
                      className={cc([C.navLink, C.smallScreen])}
                      target='_blank'
                    >
                      <ListItemText>
                        <Trans>About</Trans>
                      </ListItemText>
                    </Link>
                  </MenuItem>
                )}
                {!isWorldCorridorVisible && (
                  <MenuItem>
                    <Link
                      to='/app/dashboard/$all'
                      className={cc([C.navLink, C.smallScreen])}
                    >
                      <ListItemText>
                        <Trans>World Gallery</Trans>
                      </ListItemText>
                    </Link>
                  </MenuItem>
                )}
                {/*
                {!isEducatorCommunityVisible && (
                  <MenuItem>
                    <LightTooltip
                      title={_(msg`Coming soon`)}
                      arrow
                      placement='right'
                    >
                      <Button
                        variant='text'
                        onClick={() => { }}
                        className={cc([C.navLink, C.smallScreen])}
                        disableRipple
                        disableTouchRipple
                        style={{ padding: 0 }}
                      >
                        <ListItemText>
                          <Trans>Educator Community</Trans>
                        </ListItemText>
                      </Button>
                    </LightTooltip>
                  </MenuItem>
                )}*/}

                {!isProfileVisible && <Divider style={{ margin: '8px 0' }} />}
                {!isProfileVisible && (
                  <MenuItem>
                    <ListItemText>
                      <UserMenu showAvatar={false} />
                    </ListItemText>
                  </MenuItem>
                )}
                {!isMyCorridorsVisible && (
                  <Divider style={{ margin: '8px 0' }} />
                )}
                {!isMyCorridorsVisible && (
                  <NestedMenuItem
                    label={_(msg`My Communities`)}
                    parentMenuOpen={navMenuOpen}
                  >
                    {hostableCommunities.map(({ id, name }) => (
                      <MenuItem
                        key={id}
                        value={id}
                        onClick={() => history.push(`/app/dashboard/${id}/`)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                    {canCreateCommunity && (
                      <>
                        <Divider />
                        <MenuItem
                          onClick={() => {
                            history.push(ROUTES.COMMUNITY_SETUP_INIT);
                            setNavAnchorEl(null); // Close the menu after clicking
                          }}
                        >
                          <AddIcon
                            sx={{
                              width: 20,
                              verticalAlign: 'middle',
                              marginRight: '4px',
                            }}
                          />{' '}
                          <Trans>Start new community</Trans>
                        </MenuItem>
                      </>
                    )}
                  </NestedMenuItem>
                )}
                {!isLocaleVisible && <Divider style={{ margin: '8px 0' }} />}
                {!isLocaleVisible && (
                  <MenuItem>
                    <LocaleSwitcher />
                  </MenuItem>
                )}
              </Menu>
            </>
          ) : (
            <></>
          )}
          <>
            {isWorldCorridorVisible ? (
              <Link to='/app/dashboard/$all' className={C.navLink}>
                <Trans>World Gallery</Trans>
              </Link>
            ) : (
              <></>
            )}
            {isMyCorridorsVisible ? (
              <Grid item xs={6}>
                <Button
                  id='communities-menu-toggle'
                  aria-controls={
                    communityMenuOpen ? 'communities-menu' : undefined
                  }
                  aria-haspopup='true'
                  aria-expanded={communityMenuOpen ? 'true' : undefined}
                  onClick={(e) => setCommunitiesAnchorEl(e.currentTarget)}
                  className={C.navLink}
                  endIcon={
                    communitiesAnchorEl ? <ArrowDropUp /> : <ArrowDropDown />
                  }
                >
                  <span className={C.navLinkText}>
                    <Trans>My Communities</Trans>
                  </span>
                </Button>
                <Menu
                  id='communities-menu'
                  anchorEl={communitiesAnchorEl}
                  open={communityMenuOpen}
                  onClose={() => setCommunitiesAnchorEl(null)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  {hostableCommunities.length === 0 && (
                    <MenuItem disabled>
                      <ListItemText>
                        <Trans>
                          You are not a member of any communities yet.
                        </Trans>
                      </ListItemText>
                    </MenuItem>
                  )}
                  {hostableCommunities.map(({ id, name }) => (
                    <MenuItem
                      selected={id === communityId}
                      key={id}
                      value={id}
                      onClick={() => {
                        history.push(`/app/dashboard/${id}/`);
                        setCommunitiesAnchorEl(null);
                      }}
                    >
                      {name}
                      {
                        <span style={{ marginLeft: 8 }}>
                          {id === communityId ? '✔' : ' '}
                        </span>
                      }
                    </MenuItem>
                  ))}
                  {canCreateCommunity && (
                    <>
                      <Divider />

                      <MenuItem
                        onClick={() => {
                          history.push(ROUTES.COMMUNITY_SETUP_INIT);
                          setCommunitiesAnchorEl(null);
                        }}
                      >
                        <AddIcon
                          sx={{
                            width: 20,
                            verticalAlign: 'middle',
                            marginRight: '4px',
                          }}
                        />{' '}
                        Start new community
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </Grid>
            ) : (
              <></>
            )}
            {/*
            {isEducatorCommunityVisible ? (
              <LightTooltip title={_(msg`Coming soon`)} arrow placement='right'>
                <Button
                  variant='text'
                  onClick={() => { }}
                  className={C.navLink}
                  disableRipple
                  disableTouchRipple
                >
                  <OpenLinkIcon className={C.navLinkIcon} />
                  <span className={C.navLinkText}>
                    <Trans>Educator Community</Trans>
                  </span>
                </Button>
              </LightTooltip>
            ) : (
              <></>
            )}
            */}
            {searchVisible ? (
              <PrimarySpaceSearchInput className={cc([C.searchBar])} />
            ) : (
              <></>
            )}
          </>
        </Stack>
      }
      borderDist={p.borderDist?.toString() ? p.borderDist : isTablet ? 8 : 20}
      rightControls={
        LIMITED_CONSUMER_MODE ? undefined : (
          <Stack
            direction='row'
            justifyContent='flex-end'
            flexGrow={1}
            alignItems='center'
            spacing={1}
          >
            {isEducatorHubVisible ? (
              <Stack direction='row' alignItems='center'>
                <a
                  href='https://www.facebook.com/groups/cocobuild'
                  className={C.navLink}
                  target='_blank'
                >
                  <FacebookIcon
                    sx={{
                      color: 'white',
                      marginRight: '0.3rem',
                      verticalAlign: 'middle',
                    }}
                  />
                  <Trans>Educator Hub</Trans>
                </a>
              </Stack>
            ) : (
              <></>
            )}
            {isAboutVisible ? (
              <Link
                to='/?home=1'
                className={C.navLink}
                // style={{ marginRight: '1.5rem' }}
                target='_blank'
              >
                <Trans>About</Trans>
              </Link>
            ) : (
              <></>
            )}
            {isNotificationVisible ? (
              <>{onboardingComplete && <NotificationTrigger />}</>
            ) : (
              <></>
            )}
            {isLocaleVisible ? (
              <LocaleSwitcherIcon
              //showLanguageName={!userProfile?.onboardingComplete}
              />
            ) : (
              <></>
            )}
            {isProfileVisible ? <UserMenu /> : <></>}
          </Stack>
        )
      }
    />
  );
}
