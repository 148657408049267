import type { CommunityRecommendationResults } from '@cocoplatform/coco-rtc-shared';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { atom, useRecoilState } from 'recoil';
import { reportServerError } from 'utils/report-error';
import { SyncState } from 'utils/sync-state';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const defaultState: {
  recommendations?: CommunityRecommendationResults;
  syncState: SyncState;
} = {
  syncState: 'pending',
};

export const communityRecommendationsAtom = atom({
  key: 'communityRecommendations',
  default: defaultState,
});

export const useCommunityRecommendations = () => {
  const { _ } = useLingui();
  const [state, setState] = useRecoilState(communityRecommendationsAtom);

  return {
    ...state,
    fetchCommunityRecommendations,
  };

  function fetchCommunityRecommendations(opts?: { silent?: boolean }) {
    setState((t) => ({ ...t, syncState: 'loading' }));
    httpClient
      .get('/communities/recommended')
      .then((res) => {
        setState({ recommendations: res.data, syncState: 'loaded' });
      })
      .catch((error) => {
        console.error(error);
        if (!opts?.silent)
          reportServerError({
            title: _(msg`Failed to fetch community recommendations`),
            error,
          });
        setState((t) => ({ ...t, syncState: 'failed' }));
      });
  }
};
