import { Stack } from '@mui/material';
import GoogleSignInButton from 'components/social-login-buttons/GoogleSignIn';
import MicrosoftSignInButton from 'components/social-login-buttons/MicrosoftSignIn';
import React from 'react';

export default function SocialLoginButtons(p: {
  fullWidth?: boolean;
  onSuccess: (data: { accessToken: string; provider: string }) => void;
  signUp?: boolean;
}) {
  return (
    <Stack spacing={2}>
      <GoogleSignInButton
        signUp={p.signUp}
        onSuccess={(token) =>
          p.onSuccess({
            provider: 'google',
            accessToken: token,
          })
        }
        onError={() => {}}
      />
      {/* <MicrosoftSignInButton
        signUp={p.signUp}
        onSuccess={(token) => {
          p.onSuccess({
            provider: 'microsoft',
            accessToken: token,
          });
        }}
        onError={() => {}}
      /> */}
    </Stack>
  );
}
