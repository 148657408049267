import React, { useRef, useState, MouseEvent, useEffect } from 'react';
import Logo from './logo.svg';
import C from './HomePage.css';
import LC from 'components/prelogin-layout/PreLoginLayout.css';
import DoubleChevronDown from '!!react-svg-loader!./double-chevron-down.svg';
import SunriseIcon from './sunrise.svg';
import ClipboardIcon from '!!react-svg-loader!./clipboard.svg';
import WaveVideo from './wave-video.svg';
import LLMBanner from './little-models-video.webm';
import Mic from './mic.svg';
import SparklesIcon from './sparkles.svg';
import SparksIcon from './sparks.svg';
import BulbIcon from './light-bulb.svg';
import LinkIcon from './link-icon.svg';
import WinnerIcon from './winner-icon.svg';
import CocreateIcon from './cocreate-icon.svg';
import LLMIcon from './llm.svg';
import MegaphoneIcon from './megaphone.svg';
import NewsMIT from './news-mit.png';
import NewsEdSurge from './news-edsurge.png';
import NewsTechReview from './news-techreview.png';
import Testimonials from './Testimonials';
import CircledHeartIcon from './circled-heart.svg';
import VideoEmbed from './VideoEmbed';
import VideoOverlay from './VideoOverlay';
import NoteForm from './NoteForm';
import Header from './Header';
import { introPostUrl, llmPostUrl } from './external-links';
import ROUTES from 'constants/routes';
import PasscodePage from 'components/passcode-page/PasscodePage';
import { smoothScrollTo } from './Header';
import { Trans, msg } from '@lingui/macro';
import LocaleSwitcher from 'components/locale-switcher/LocaleSwitcher';
import { useLingui } from '@lingui/react';
import CloseIcon from '@mui/icons-material/Close';
import cc from 'classnames';
import InviteJoinForm from 'components/invite-join-form/InviteJoinForm';
import { useAuthState } from 'utils/auth-state';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import { useHistory } from 'react-router-dom';

export default function HomePage() {
  const { _ } = useLingui();
  const [showingVideoOverlay, setShowingVideoOverlay] = useState(false);
  const pageContainerRef = useRef<HTMLDivElement>(null);
  const { didSetPasscode, savedToken } = useAuthState();
  const passcodePageVisible = REQUIRE_PASSCODE && !didSetPasscode;

  const passcodeFormVisible = passcodePageVisible;
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    if (!PAGES_HOME_ENABLED) {
      // Home page is not enabled - take users to landing page
      location.href = ROUTES.LANDING;
      return;
    }
    if (passcodePageVisible || passcodeFormVisible) {
      return;
    }
    // We want to keep the join code link small
    //
    // If join code is present as a hash then redirect to
    // community join page
    const codeMatch = location.hash
      .slice(1)
      .match(/^\/?([a-z0-9]{6})?(\/classroom)?$/i);
    if (codeMatch?.[1]) {
      location.href = `/app/join/${codeMatch[1]}${codeMatch[2] ? '/classroom' : ''}`;
      return;
    }

    const params = new URLSearchParams(window.location.search);

    if (params.has('home')) {
      params.delete('home');
      history.replace({
        search: params.toString(),
      });
    } else if (savedToken) {
      // User is already logged in, redirect to app
      location.href = ROUTES.LANDING;
      return;
    }

    setLoading(false);
  }, [passcodePageVisible, passcodeFormVisible, savedToken]);

  if (passcodePageVisible) {
    return <PasscodePage formVisible={passcodeFormVisible} />;
  }

  if (loading) {
    // Wait to complete initial redirection steps
    return <></>;
  }

  return (
    <div
      className={C.pageContainer}
      ref={pageContainerRef}
      id='js-home-page-container'
    >
      {showingVideoOverlay ? (
        <VideoOverlay
          onClose={() => {
            setShowingVideoOverlay(false);
          }}
        />
      ) : null}
      <Header {...{ setShowingVideoOverlay }} />
      <h1 className={C.sitePrimaryTitle}>
        <Trans>CoCo - Being. Creative. Together</Trans>
      </h1>
      {/* AI Education */}
      <div className={C.LLMBannerContainer}>
        <div
          style={{
            color: 'inherit',
            borderRadius: '6px',
          }}
        >
          <h3
            className={cc(C.bannerHeader, C.topBanner)}
            style={{
              background: 'var(--col-washed-out-green)',
              transform: 'rotate(-2deg)',
              marginTop: '35px',
              verticalAlign: 'middle',
            }}
            id='llm-banner'
          >
            <img src={WinnerIcon} className={C.headerIcon} alt='winner icon' />

            <Trans>
              Winners, 2024 Global EdTech Prize for our new AI Education tool
            </Trans>

            <a
              href={llmPostUrl}
              target='_blank'
              rel='noreferrer noopener'
              style={{
                display: 'inline-block',
                padding: '0.3rem 1.3rem',
                marginLeft: '1rem',
                fontSize: '1.1rem',
                verticalAlign: 'middle',
                color: 'black',
                borderRadius: '6px',
                boxShadow: '1px 1px 4px 3px rgba(0, 0, 0, 0.2)',
              }}
            >
              <span
                style={{
                  verticalAlign: 'middle',
                }}
              >
                <Trans>Read Article</Trans>
              </span>
              <img
                src={LinkIcon}
                className={C.headerIcon}
                style={{
                  marginLeft: '1rem',
                  marginRight: '0',
                  width: '1.1rem',
                  verticalAlign: 'middle',
                }}
                alt='link icon'
              />
            </a>
          </h3>
        </div>
        <iframe
          width='675'
          src='https://www.youtube.com/embed/zDHdKREA5og?rel=0'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          referrerPolicy='strict-origin-when-cross-origin'
          allowFullScreen
          className={C.llmBannerImage}
        ></iframe>
        <br></br>
        <h3 className={C.secondaryIntro}>
          <Trans>Educators from 85+ countries are excited to use CoCo!</Trans>
        </h3>
      </div>
      <div className={C.orgOuter}>
        <InviteJoinForm />
        <div className={C.orgInfoOuter}>
          <div className={C.orgInfo}>
            <Trans>
              Co-imagined and co-created with love and care by a tiny team of{' '}
              <a href='#support' onClick={smoothScrollTo('#support')}>
                <span className={C.teamLink}>MIT PhD Researchers</span>
              </a>
            </Trans>
          </div>
        </div>
      </div>
      <h3
        className={C.bannerHeader}
        style={{
          background: 'var(--col-washed-out-pink)',
          transform: 'rotate(2deg)',
        }}
      >
        <a id='coco-intro' />
        <img src={CocreateIcon} className={C.headerIcon} alt='cocreate icon' />
        <Trans>
          Co-create, Code, Collaborate —{' '}
          <span style={{ fontWeight: '900' }}>WITH PEERS IN REAL-TIME!</span>
        </Trans>
      </h3>
      <h2 className={C.primaryIntroOuter} style={{ fontSize: 'inherit' }}>
        <span>
          <span className={C.primaryIntroBox}>
            <Trans>
              A calm, collaborative learning platform for young people to engage
              in...
            </Trans>
          </span>
          <span className={C.primaryIntroTagsContainer}>
            <ul className={C.primaryIntroTags}>
              <Trans>
                <li>Being.</li>
                <li>Creative.</li>
                <li>Together.</li>
              </Trans>
            </ul>
          </span>
          <img
            src={WaveVideo}
            className={C.waveVideoIcon}
            alt={_(msg`wave icon`)}
          />
          <img src={Mic} className={C.micIcon} alt={_(msg`mic icon`)} />
        </span>
        <h3 className={C.headingSubtext}>
          <Trans>...in the era of AI</Trans>
        </h3>
      </h2>
      <div className={C.vidPre}>
        <div>
          <Trans>Watch the video below to see CoCo in action</Trans>
        </div>
        <div id='video-primary'>
          <DoubleChevronDown className={C.vidDownIcon} />
        </div>
      </div>
      <VideoEmbed />
      <h3
        className={C.bannerHeader}
        style={{
          background: 'var(--col-washed-out-lightgreen)',
          transform: 'rotate(2deg)',
        }}
      >
        <img src={SparksIcon} className={C.headerIcon} alt='sparks icon' />
        <Trans>
          AI education; Collaborative computing; Private communities; and more!
        </Trans>
      </h3>
      <Testimonials />

      <h3
        className={C.bannerHeader}
        style={{
          background: 'var(--col-washed-out-orange)',
          transform: 'rotate(-2deg)',
        }}
        id='motivation-banner'
      >
        <a id='motivations' />
        <img src={BulbIcon} className={C.headerIcon} alt='bulb icon' />
        <Trans>Learn more about the ideas and values underlying CoCo</Trans>
      </h3>
      <div className={C.excerptContainer}>
        <div className={C.excerptContentOuter}>
          <div className={C.excerptContent}>
            <p>
              <Trans>
                We consciously chose to design the CoCo space as a shared
                digital space for co-creating projects in real-time and not
                necessarily for collaborating on the same project in real-time.
              </Trans>
            </p>

            <p>
              <Trans>
                Collaboration often exclusively implies{' '}
                <span style={{ fontStyle: 'italic' }}>working together</span>,
                but a 'shared space' more generally implies{' '}
                <span style={{ fontStyle: 'italic' }}>being together</span> and
                hence is inclusive of collaboration as one of the ways of
                engaging with others but not the only way. There is no denying
                that collaborating with others can be a really joyful,
                productive, and energy-giving experience, but it does not{' '}
                <span style={{ fontStyle: 'italic' }}>always</span> work well
                for <span style={{ fontStyle: 'italic' }}>everyone</span>{' '}
                involved. The reasons can be many--not being able to learn and
                work at your own pace, not feeling comfortable to express
                yourself in the group, feeling or being judged, someone hogging
                the work, perceptions of unfairness in contributions and credit,
                and other negative feelings that can sometimes arise out this
                experience.
              </Trans>
            </p>
          </div>
          <div className={C.excerptGrad} />
        </div>

        <div className={C.excerptCTAContainer}>
          <a
            className={C.excerptCTA}
            href={introPostUrl}
            target='_blank'
            rel='noreferrer noopener'
          >
            <ClipboardIcon />
            <Trans>Read the blog post</Trans>
          </a>
        </div>
      </div>

      <h3
        className={C.bannerHeader}
        style={{
          background: 'var(--col-washed-out-yellow)',
          transform: 'rotate(2deg)',
        }}
        id='press-banner'
      >
        <a id='press' />
        <img
          src={MegaphoneIcon}
          className={C.headerIcon}
          alt={_(msg`heart icon`)}
        />
        <Trans>Featured in MIT Technology Review, MIT News, EdSurge...</Trans>
      </h3>

      <div className={C.pressLink}>
        <div>
          <a
            href='https://www.technologyreview.com/2024/10/25/1106168/kids-are-learning-how-to-make-their-own-little-language-models/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={NewsTechReview}
              alt='description'
              style={{ width: '300px', borderRadius: '5px', display: 'block' }}
            />
          </a>
        </div>
        <div>
          <a
            href='https://news.mit.edu/2023/coco-real-time-co-creative-learning-platform-young-people-0530'
            target='_blank'
            rel='noopener noreferrer'
            style={{ cursor: 'pointer' }}
          >
            <img
              src={NewsMIT}
              alt='description'
              style={{ width: '300px', borderRadius: '5px', display: 'block' }}
            />
          </a>
        </div>
        <div>
          <a
            href='https://www.edsurge.com/news/2023-05-09-a-decade-into-experiments-with-gamification-edtech-rethinks-how-to-motivate-learners'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              src={NewsEdSurge}
              alt='description'
              style={{ width: '300px', borderRadius: '5px', display: 'block' }}
            />
          </a>
        </div>
      </div>
      <h3
        className={C.bannerHeader}
        style={{
          background: 'var(--col-washed-out-red)',
          transform: 'rotate(-2deg)',
        }}
      >
        <img
          src={SunriseIcon}
          className={C.headerIcon}
          alt={_(msg`sunrise icon`)}
        />
        <Trans>Want to try CoCo with your community?</Trans>
      </h3>
      <InviteJoinForm />
      <div className={C.inviteDisclaimer}>
        <p>
          <Trans>
            Thank you for your excitement, encouragement, and patience as we
            work towards sharing invites for the beta!
          </Trans>
        </p>
      </div>
      <h3
        className={C.bannerHeader}
        style={{
          background: 'var(--col-washed-out-turquoise)',
          transform: 'rotate(2deg)',
        }}
        id='support-banner'
      >
        <a id='support' />
        <img
          src={CircledHeartIcon}
          className={C.headerIcon}
          alt={_(msg`heart icon`)}
        />
        <Trans>Do you resonate with these ideas? We'd love to connect!</Trans>
      </h3>

      <NoteForm />
      <footer className={C.footer}>
        <div>
          <img src={Logo} className={C.footerLogo} alt='coco logo' />
          <div className={C.privacyLinkContainer}>
            <a href='/privacy-terms' className={C.privacyLink}>
              <Trans>Privacy</Trans>
            </a>
            &
            <a href='/terms-of-use' className={C.privacyLink}>
              <Trans>Terms</Trans>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );

  function scrollToTop(e: MouseEvent) {
    e.stopPropagation();
    e.preventDefault();
    pageContainerRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
