import { UserProfile } from "@cocoplatform/coco-rtc-shared";
import { atom } from "@cocoplatform/coco-rtc-client";
import { SyncState } from "utils/sync-state";

export interface UserProfileState {
    userProfile?: UserProfile;
    syncState: SyncState;
}


export const userProfileAtom = atom<UserProfileState>({
    syncState: 'pending',
});