import { Box, Typography } from '@mui/material';
import CocoModal from 'components/coco-modal/coco-modal';
import React, { ReactNode, useEffect } from 'react';
import InviteJoinForm from 'components/invite-join-form/InviteJoinForm';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

export default function AccessDeniedModal({
  onClose,
  email,
  title,
}: {
  onClose: () => void;
  email?: string;
  title?: ReactNode;
}) {
  const { _ } = useLingui();

  const track = useTrack();

  useEffect(() => {
    track(EVENTS.EMAIL_NOT_APPROVED, INTERACTIONS.SYSTEM, {
      email,
    });
  }, []);

  return (
    <CocoModal
      onClose={() => {
        onClose();
        track(EVENTS.CLOSE_MODAL, INTERACTIONS.CLICK, {
          modal: 'AccessDeniedModal',
        });
      }}
      header={title ?? _(msg`This email has not been approved for access`)}
    >
      <Box p={4}>
        <Typography textAlign='center'>
          <Trans>
            CoCo is currently in private beta. Please join the invite list and
            fill out a short form to request access.
          </Trans>
        </Typography>
        <Box mt={4} />
        <InviteJoinForm email={email} onClose={onClose} autofocus />
      </Box>
    </CocoModal>
  );
}
