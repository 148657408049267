import { Box, Divider, Fade, Stack, Typography } from '@mui/material';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import PrivacyFooter from 'components/PrivacyFooter/PrivacyFooter';
import SocialLoginButtons from 'components/social-login-buttons/SocialLoginButtons';
import ROUTES from 'constants/routes';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuthState } from 'utils/auth-state';
import qs from 'query-string';
import { msg, Trans } from '@lingui/macro';
import LocaleSwitcher from 'components/locale-switcher/LocaleSwitcher';
import { EMAIL_REGEX } from 'utils/validation';
import { LoadingButton } from '@mui/lab';
import ControlledTextField from 'components/forms/ControlledTextField';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useQueryParams } from 'utils/hooks/useQueryParams';
import { submitCode } from 'components/onboarding-flow/educational/EducationalOnboardingSignUp';
import CodeForm from 'components/code-form/CodeForm';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

export default function PersonalOnboardingSignUp() {
  const { saveToken } = useAuthState();
  const history = useHistory();

  const { email } = useQueryParams();

  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm(
    email
      ? {
          defaultValues: { email },
        }
      : undefined,
  );
  const [isDispatching, setDispatching] = useState(false);
  const [dispatched, setDispatched] = useState(false);

  const track = useTrack();

  async function submit(data: any) {
    setDispatching(true);
    const res = await submitCode(data.email);
    setDispatching(false);

    if (res?.success) {
      setDispatched(true);
    }
  }

  if (dispatched) {
    return (
      <CodeForm
        email={getValues('email')}
        onSuccess={() => {
          history.push(ROUTES.ONBOARDING_PERSONAL_PROFILE_DETAILS);
        }}
        userType='PERSONAL'
      />
    );
  }

  return (
    <Fade in>
      <Stack
        component='form'
        onSubmit={handleSubmit(submit)}
        mt={4}
        alignItems='center'
      
      >
        <Typography variant='h1'>
          <Trans>Sign up with email</Trans>
        </Typography>
        <Typography textAlign='center' variant='subtitle1' mt={3}>
          <Trans>
            If you're <strong>under the age of 13</strong>, please ask a parent
            <br />
            or guardian to sign up with their email address.
          </Trans>
        </Typography>
        <Box mt={4} />
        <ControlledTextField
          inputProps={{
            'data-analytics': EVENTS.EMAIL_INPUT,
            onFocus: () => {
              track(EVENTS.EMAIL_INPUT, INTERACTIONS.FOCUS);
            },
          }}
          variant='outlined'
          size='small'
          name='email'
          id='email_control'
          placeholder='name@email.com'
          required
          rules={{
            required: true,
            pattern: EMAIL_REGEX,
          }}
          helperText={
            errors.email ? _(msg`Please enter a valid email`) : undefined
          }
          error={!!errors.email}
          {...{ control }}
        />
        <LoadingButton
          data-analytics={EVENTS.CONTINUE}
          type='submit'
          loadingPosition='end'
          variant='contained'
          sx={{ mt: 2 }}
          loading={isDispatching}
          fullWidth
        >
          <strong>
            <Trans>Continue</Trans>
          </strong>
        </LoadingButton>

        <Box mt={2} />

        <Divider sx={{ width: '100%' }}>
          <Trans>or</Trans>
        </Divider>
        <Box mt={2} mb={4} width='100%'>
          <SocialLoginButtons
            signUp
            fullWidth
            onSuccess={async ({ accessToken, provider }) => {
              try {
                const { data } = await httpClient.get(
                  `/auth/oauth/verification?${qs.stringify({
                    accessToken,
                    provider,
                    userType: 'PERSONAL',
                  })}`,
                );
                saveToken(data.token, true);
              } catch (err) {
                console.error(err);
              }
            }}
          />
        </Box>
        <PrivacyFooter />
        <div style={{ marginTop: 24, textAlign: 'center' }}>
          <LocaleSwitcher />
        </div>
      </Stack>
    </Fade>
  );
}
