import * as z from "zod"
import * as utils from "./utils"
import { SpaceSchema } from "./endpoints/spaces/types"
import { UserSchema } from "./endpoints/users/types"
import { FileUploadSchema, FileUpload } from "./utils/file-upload"

export * from "./endpoints/spaces/types"
export * from "./endpoints/world-corridor/types"
export * from "./endpoints/communities/types"
export * from "./endpoints/sprites/types"
export * from "./endpoints/assets/types"
export * from "./endpoints/users/types"
export * from "./endpoints/notifications/types"
export * from "./endpoints/themes/types"
export * from "./endpoints/projects/types"
export * from "./endpoints/organizations/types"
export * from "./endpoints/surveys/types"
export * from "./ws-messages"
export * from "./consts/report-reasons"
export * from "./utils/file-upload"

export { FileUploadSchema, utils, FileUpload }

export const UserParticipatedSpaceCollectionSchema = z.object({
    userUpcoming: SpaceSchema.array(),
    otherUpcoming: SpaceSchema.array(),
    past: SpaceSchema.array(),
})

export type UserParticipatedSpaceCollection = z.TypeOf<
    typeof UserParticipatedSpaceCollectionSchema
>

export const OrganizationSchema = z.object({
    id: z.string(),
    name: z.string(),
    website: z.string().optional(),
})


export const NotificationSchema = z.object({
    id: z.string(),
    content: z.string().nullish(),
    eventName: z.string().nullish(),
    primaryActionUrl: z.string().nullish(),
})

export type Notification = z.TypeOf<typeof NotificationSchema>

export const UserProfileSchema = z.object({
    id: z.string(),
    username: z.string().nullish(),
    firstName: z.string().nullish(),
    lastName: z.string().nullish(),
    guardianFirstName: z.string().nullish(),
    guardianLastName: z.string().nullish(),
    avatarUrl: z.string().nullish(),
    about: z.string().nullish(),
    countryCode: z.string().nullish(),
    city: z.string().nullish(),
    currentWorkDescription: z.string().nullish(),
    email: z.string().nullish(),
    gender: z.string().nullish(),
    birthMonth: z.number().nullish(),
    birthYear: z.number().nullish(),
    country: z.string().nullish(),
    acceptsEmail: z.boolean().nullish(),
    profileUpdatedAt: z.number().nullish(),
    instanceRoles: z.string().array(),
    userType: z.string().nullish(),
    onboardingComplete: z.boolean().nullish(),
    educationalAccessApproved: z.boolean().nullish(),
    tutorialsCompleted: z.array(z.string()).nullish(),
})

export type UserProfile = z.TypeOf<typeof UserProfileSchema>

export const UserProfileInputSchema = UserProfileSchema.omit({
    username: true,
})
    .partial()
    .and(
        z.object({
            password: z.string().nullish(),
        })
    )

export type UserProfileInput = z.TypeOf<typeof UserProfileInputSchema>

export const SpaceCategorySchema = z.object({
    id: z.string(),
    name: z.string(),
})

export type SpaceCategory = z.TypeOf<typeof SpaceCategorySchema>

export const PresenceInfoSchema = z.object({
    users: UserSchema.and(
        z.object({
            isOnline: z.boolean(),
        })
    ).array(),
})

export type PresenceInfo = z.TypeOf<typeof PresenceInfoSchema>

export const PrefsQueryInputSchema = z.object({
    names: z.string().array().nullish(),
    userId: z.string(),
})

export type PrefsQueryInput = z.TypeOf<typeof PrefsQueryInputSchema>

export const PrefsInputSchema = z.object({
    prefs: z.record(z.any()),
    userId: z.string(),
})

export type PrefsInput = z.TypeOf<typeof PrefsInputSchema>

export const PrefsResponseSchema = z.object({
    prefs: z.record(z.any()),
})

export type PrefsResponse = z.TypeOf<typeof PrefsResponseSchema>

export const EnsureProjectPreviewInputSchema = z.object({
    snapshotId: z.string(),
    userId: z.string()
})
