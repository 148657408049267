// routes for client-side routing
const ROUTES = {
  ROOT: '/',

  // Landing routes for limited consumer mode:
  // (app prefix not needed because home page is not present)
  CORRIDOR_FORM: '/corridor/:communityCode',
  SPACE_FORM: '/space/:spaceCode',

  // App routes:
  PRIVACY_TERMS: '/app/privacy-terms',
  TERMS_OF_USE: '/app/terms-of-use',
  LANDING: '/app/',
  WELCOME: '/app/welcome',
  PASSCODE: '/app/passcode',
  SIGN_UP: '/app/signup',
  SIGN_IN: '/app/signin',
  PROFILE_UPDATE: '/app/profile',
  EMAIL_UPDATE: '/app/user/email/updateJoinCodePage',
  ABOUT: '/app/about',
  LOGIN: '/app/login',
  HOME: '/app/home',
  HOME_TAB: '/app/home/:tabId',
  JOIN_CODE: '/app/join/:code',
  JOIN_CODE_POST_COMPLETION: '/app/join/:code/complete',
  JOIN_CODE_WITH_KEY: '/app/join/:code/loginkey',
  JOIN_CODE_WITH_EMAIL_SIGNIN: '/app/join/:code/email/signin',
  JOIN_CODE_WITH_EMAIL_SIGNUP: '/app/join/:code/email/signup',
  JOIN_CODE_WITH_CLASSROOM_SIGNIN: '/app/join/:code/classroom',
  JOIN_CODE_PROFILE_UPDATE: '/app/join/:code/profile/update',
  JOIN_USER_CODE: '/app/join/:code/u/:username',
  COMMUNITY_HOME: '/app/communities/:id/:tabId',
  COMMUNITY_HOME_NESTED: '/app/communities/:id/:tabId/:sectionId',
  SPACE_EDITOR: '/app/spaces/:id/editor',
  ACCOUNT_SETUP: '/app/account/setup',
  COMMUNITY_SETUP_INIT: '/app/community/setup',
  COMMUNITY_SETUP_INTENT: '/app/community/setup/intent',
  LOC_SETUP: '/app/loc/setup',
  ORG_SETUP: '/app/org/setup',
  COMMUNITY: '/app/community/:id',
  PROFILE: '/app/profile',
  POST_PASSWORD_RESET: '/app/password-reset/post-submit',
  PRINTABLE_USER_LIST: '/app/communities/:id/user-list/printable',
  JAMS_DASHBOARD: '/app/dashboard/:communityId/jams/:jamId',
  COMMUNITY_DASHBOARD: '/app/dashboard/:communityId',
  COMMUNITY_DASHBOARD_SETTINGS: '/app/dashboard/:communityId/settings',
  COMMUNITY_DASHBOARD_INVITATION: '/app/dashboard/:communityId/invitation',
  COMMUNITY_DASHBOARD_SPACES: '/app/dashboard/:communityId/spaces',
  COMMUNITY_DASHBOARD_SPACE: '/app/dashboard/:communityId/spaces/:spaceId',
  COMMUNITY_DASHBOARD_SPACE_EDIT:
    '/app/dashboard/:communityId/spaces/:spaceId/edit',
  COMMUNITY_DASHBOARD_MEMBERS: '/app/dashboard/:communityId/members',
  COMMUNITY_DASHBOARD_NEW_MEMBER:
    '/app/dashboard/:communityId/members/new/:sectionId?',
  DASHBOARD: '/app/dashboard',
  MEDIA_LIB: '/app/dashboard/:communityId/media-lib',
  MEDIA_LIB_SECTION: '/app/dashboard/:communityId/media-lib/:sectionId',
  ADMIN_SURVEYS_EXPLORER: '/app/admin/surveys',
  ADMIN_EFFECTS_EXPLORER: '/app/admin/effects',
  POST_UNSUBSCRIBE: '/app/post-unsubscribe',
  ONBOARDING: '/app/onboarding',
  ONBOARDING_PERSONAL_SIGNUP: '/app/onboarding?step=PERSONAL_SIGNUP',
  ONBOARDING_EDUCATIONAL_SIGNUP: '/app/onboarding?step=EDUCATIONAL_SIGNUP',
  ONBOARDING_EDUCATIONAL_PROFILE_DETAILS:
    '/app/onboarding?step=EDUCATIONAL_PROFILE_DETAILS',
  ONBOARDING_EDUCATIONAL_ORG_DETAILS:
    '/app/onboarding?step=EDUCATIONAL_ORGANIZATION_DETAILS',
  ONBOARDING_EDUCATIONAL_CORRIDOR_SETUP:
    '/app/onboarding?step=EDUCATIONAL_CORRIDOR_SETUP',
  ONBOARDING_EDUCATIONAL_INVITE_CORRIDOR:
    '/app/onboarding?step=EDUCATIONAL_INVITE_CORRIDOR',
  ONBOARDING_LOADING: '/app/onboarding?step=ONBOARDING_LOADING',
  ONBOARDING_PERSONAL_PROFILE_DETAILS: '/app/onboarding?step=PERSONAL_PROFILE_DETAILS',
  ONBOARDING_PERSONAL_CORRIDOR_SETUP:
    '/app/onboarding?step=PERSONAL_CORRIDOR_SETUP',
  ONBOARDING_PERSONAL_INVITE_CORRIDOR:
    '/app/onboarding?step=PERSONAL_INVITE_CORRIDOR',
  SERVER_DOWN: '/app/server-down',
  TROUBLESHOOT_NETWORK: '/app/troubleshoot-network',
  WORLD_CORRIDOR_ADMIN_PANEL: '/app/admin/world-corridor',
  WORLD_CORRIDOR_ADMIN_PANEL_SPACES: '/app/admin/world-corridor/spaces',
  WORLD_CORRIDOR_ADMIN_PANEL_VIDEOS: '/app/admin/world-corridor/videos',
  WORLD_CORRIDOR_ADMIN_PANEL_SPACE_DETAILS:
    '/app/admin/world-corridor/spaces/:id',
  WORLD_CORRIDOR_ADMIN_PANEL_VIDEO_DETAILS:
    '/app/admin/world-corridor/videos/:id',
};

// build reverse map
export const ROUTES_REVERSE = Object.entries(ROUTES).reduce((acc, [key, value]) => {
  acc[value] = key;
  return acc;
}, {} as Record<string, string>);

export interface DashboardParams {
  communityId?: string;
  sectionId?: string;
}

export default ROUTES;
