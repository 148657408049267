import React, { CSSProperties } from 'react';
import C from './SharedLayout.css';
import cc from 'classcat';
import Helmet from 'react-helmet';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useHistory } from 'react-router-dom';
import { MinSidebarCommunityList } from 'components/min-sidebar-community-list/MinSidebarCommunityList';
import { Trans, msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { EVENTS } from 'utils/mixpanel';

const SharedLayout: React.FC<{
  header?: React.ReactChild;
  footer?: React.ReactChild;
  body?: React.ReactChild;
  children?: React.ReactChild;
  onSuccess?: () => void;
  wide?: boolean;
  medium?: boolean;
  style?: CSSProperties;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  backHref?: string | Function;
  showCorridorPicker?: boolean;
}> = (p) => {
  const { _ } = useLingui();
  const history = useHistory();
  const theme = useTheme();
  return (
    <div
      className={cc([
        {
          [C.pageContainer]: true,
          [C.wide]: p.wide,
          [C.medium]: p.medium,
          [C.narrow]: !p.wide && !p.medium,
        },
        p.className,
      ])}
      style={p.style}
    >
      <Helmet>
        <title>
          {`${_(msg`CoCo`)} - ${_(msg`Being. Creative. Together.`)}`}
        </title>
      </Helmet>
      {p.header}
      {p.body ?? (
        <Stack direction='row'>
          {p.showCorridorPicker && (
            <Box
              sx={{
                background: 'var(--color-base)',
                borderRight: '1px solid var(--color-border)',
                flexBasis: '60px',
                flexShrink: 0,
                flexGrow: 0,
                height: 'calc(100vh - 50px)',
                overflow: 'auto',
              }}
            >
              <MinSidebarCommunityList />
            </Box>
          )}
          <div className={C.pageInner}>
            {p.backHref && (
              <Stack
                data-analytics={EVENTS.BACK}
                color={theme.palette.primary.main}
                direction='row'
                alignItems='center'
                onClick={() => {
                  if (!p.backHref) return;
                  if (p.backHref instanceof Function) {
                    p.backHref();
                    return;
                  }
                  history.push(p.backHref);
                }}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                  paddingTop: {
                    xs: '1rem',
                    sm: '0px',
                  },
                  paddingLeft: {
                    xs: '1rem',
                    sm: '0px',
                  },
                }}
              >
                <ChevronLeftIcon fontSize='large' />
                &nbsp;
                <Typography
                  fontSize='large'
                  sx={{}}
                  color={theme.palette.primary.main}
                >
                  <Trans>Back</Trans>
                </Typography>
              </Stack>
            )}
            <div className={C.pageBody}>{p.children}</div>
          </div>
        </Stack>
      )}
      {p.footer}
    </div>
  );
};

export default SharedLayout;
