import {
  CommunityDetails,
  InvitationCode,
} from '@cocoplatform/coco-rtc-shared';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import { atom, useRecoilState } from 'recoil';
import { SyncState } from 'utils/sync-state';
import * as communityCache from '../caches/community';
import { reportServerError } from 'utils/report-error';
import { useEffect, useMemo } from 'react';
import omit from 'lodash/omit';
import { produce } from 'immer';
import { useEvt } from 'evt/hooks';
import { spaceCreatedEvt } from 'atoms/spaces';
import * as CMR from 'constants/community-roles';
import { msg } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CocoLogger } from '@cocoplatform/coco-logger';

const defaultState: {
  communities: Record<
    string,
    {
      community?: CommunityDetails;
      syncState: SyncState;
    }
  >;
  defaultCommunityId?: string;
} = {
  communities: {},
};

export const communityDetailsAtom = atom({
  key: 'communityDetails',
  default: defaultState,
});

export const useCommunityDetails = (opts: {
  communityId?: string;
  autoLoad?: boolean;
}) => {
  const { _ } = useLingui();
  const [state, setState] = useRecoilState(communityDetailsAtom);
  const { communityId, autoLoad } = opts;
  const communityKey = communityId ?? state.defaultCommunityId ?? '$primary';

  useEffect(() => {
    const shouldLoad =
      (!communityId || !state.communities[communityId]) && autoLoad !== false;
    if (communityId && shouldLoad) {
      const cached = communityCache.getCommunityDetails(communityId);
      if (cached) {
        setCommunityDetails(() => cached);
      }
    }
    if (shouldLoad) {
      loadCommunityDetails();
    }
  }, [communityId]);

  const setInviteCode = (communityId: string, code: InvitationCode) => {
    setState(
      produce((s) => {
        const community = s.communities[communityId]?.community;
        if (!community) return;
        community.genericInvitationCode = code;
      }),
    );
  };

  const setCommunityDetails = (
    update: (community?: CommunityDetails) => CommunityDetails | null,
  ) => {
    setState((d) => {
      const oldCommunity = d.communities[communityKey];
      const newCommunity = update(oldCommunity?.community);
      if (newCommunity) {
        return {
          ...d,
          defaultCommunityId:
            (communityId ? undefined : newCommunity?.id) ??
            d.defaultCommunityId,
          communities: {
            ...d.communities,
            [communityKey]: { syncState: 'loaded', community: newCommunity },
            [newCommunity.id]: { syncState: 'loaded', community: newCommunity },
          },
        };
      } else {
        return {
          ...d,
          communities: omit(d.communities, [communityKey]),
        };
      }
    });
  };

  useEvt((ctx) => {
    spaceCreatedEvt.attach(ctx, () => {
      setCommunityDetails((c) => {
        if (!c) return null;
        return { ...c, totalSpaceCount: (c.totalSpaceCount ?? 0) + 1 };
      });
    });
  }, []);

  const setCommunitySyncState = (syncState: SyncState) => {
    setState((d) => ({
      ...d,
      communities: {
        ...d.communities,
        [communityKey]: { ...d.communities[communityKey], syncState },
      },
    }));
  };

  const clearCommunityDetails = () => {
    setState((d) => {
      const communities = omit(d.communities, [communityKey]);
      return {
        ...d,
        communities,
      };
    });
  };

  const loadCommunityDetails = async () => {
    try {
      setCommunitySyncState('loading');
      console.log('community using client', httpClient.interceptors.request);
      const { data } = await httpClient.get(`/community/${communityKey}`);
      if (!data || communityId !== data.id) {
        clearCommunityDetails();
      }
      if (!data) {
        communityCache.removeCommunity(communityKey);
      }
      setCommunityDetails(() => data);
      setCommunitySyncState('loaded');
      if (data) {
        communityCache.storeCommunityDetails(data);
      }
    } catch (error) {
      // eslint-disable-next-line lingui/no-unlocalized-strings
      CocoLogger.error('Failed to fetch community details', error);
      setCommunitySyncState('failed');
    }
  };

  const patchCommunityDetails = async (
    patch: (c: CommunityDetails) => CommunityDetails,
  ) => {
    setCommunityDetails((community) => {
      if (community) {
        const updatedCommunity = patch(community);
        communityCache.storeCommunityDetails(updatedCommunity);
        return updatedCommunity;
      } else {
        console.warn(
          // eslint-disable-next-line lingui/no-unlocalized-strings
          'Not attempting to patch because community missing',
          communityKey,
        );
        return community ?? null;
      }
    });
  };

  const markMemberApproved = (userId: string) => {
    patchCommunityDetails((it) => ({
      ...it,
      members: it.members?.map((it) => {
        if (it.user?.id === userId) return { ...it, isApproved: true };
        return it;
      }),
      coCreatorCount: it.coCreatorCount == null ? null : it.coCreatorCount + 1,
    }));
  };

  const markAllMembersApproved = () => {
    patchCommunityDetails((it) => ({
      ...it,
      members: it.members?.map((it) => {
        return { ...it, isApproved: true };
      }),
    }));
  };

  const removeMember = (users: { userId?: string; email?: string }[]) => {
    patchCommunityDetails((it) => {
      let { coCreatorCount } = it;
      const members = [...it.members!];
      for (const user of users) {
        const memberIdx = members.findIndex((it) => {
          if (user.userId && it.user?.id && it.user.id === user.userId)
            return true;
          if (user.email && it.email && it.email === user.email) return true;
          return false;
        });
        if (memberIdx == null || memberIdx < 0) continue;
        const [member] = members.splice(memberIdx, 1);
        if (coCreatorCount && member.isApproved && member.roles.length > 0) {
          coCreatorCount -= 1;
        }
      }

      return {
        ...it,
        coCreatorCount,
        members,
      };
    });
  };

  const removeEmail = (email: string) => {
    patchCommunityDetails((it) => ({
      ...it,
      genericInvitationCode: it.genericInvitationCode
        ? {
          ...it.genericInvitationCode,
          emails: it.genericInvitationCode.emails?.filter(
            (curEmail) => curEmail !== email,
          ),
        }
        : it.genericInvitationCode,
    }));
  };

  const removeEmailDomain = (emailDomain: string) => {
    patchCommunityDetails((it) => ({
      ...it,
      genericInvitationCode: it.genericInvitationCode
        ? {
          ...it.genericInvitationCode,
          emailDomains: it.genericInvitationCode.emailDomains?.filter(
            (curDomain) => curDomain !== emailDomain,
          ),
        }
        : it.genericInvitationCode,
    }));
  };

  const changeRoles = (userId: string, roles: string[]) => {
    patchCommunityDetails((it) => ({
      ...it,
      members: it.members?.map((m) =>
        m.user?.id === userId
          ? {
            ...m,
            roles,
          }
          : m,
      ),
    }));
  };

  const changeInvitationRole = (invitationCodeId: string, roles: string[]) => {
    patchCommunityDetails((it) => ({
      ...it,
      members: it.members?.map((m) =>
        m.invitationCodeId === invitationCodeId
          ? {
            ...m,
            roles,
          }
          : m,
      ),
    }));
  };

  const updateCommunityBanner = async (file: File) => {
    const formData = new FormData();
    formData.append('banner', file);
    formData.append('input', '{}');
    try {
      const {
        data: { bannerUrl },
      } = await httpClient.put(`/community/${communityId}`, formData);
      patchCommunityDetails((community) => {
        return { ...community, bannerUrl };
      });
    } catch (error) {
      reportServerError({
        title: _(msg`Failed to update community cover image`),
        error,
      });
    }
  };

  const updateCommunity = async (details: Partial<CommunityDetails>) => {
    const formData = new FormData();
    formData.append('input', JSON.stringify(details));
    try {
      await httpClient.put(`/community/${communityId}`, formData);
      patchCommunityDetails((community) => {
        return { ...community, ...details };
      });
    } catch (error) {
      reportServerError({
        title: _(msg`Failed to update community cover image`),
        error,
      });
    }
  };

  const createGroup = async (name: string, userIds: string[]) => {
    try {
      const { data: group } = await httpClient.post(
        `/community/${communityId}/groups`,
        {
          name,
          userIds,
        },
      );
      patchCommunityDetails((c) => {
        return { ...c, groups: [...c.groups, group] };
      });
    } catch (error) {
      reportServerError({
        title: _(msg`Failed to create group`),
        error,
      });
    }
  };

  const deleteGroup = async (groupId: string) => {
    try {
      await httpClient.delete(`/community/${communityId}/group/${groupId}`);
      patchCommunityDetails((c) => {
        return { ...c, groups: c.groups.filter((g) => g.id !== groupId) };
      });
    } catch (error) {
      reportServerError({
        title: _(msg`Failed to delete group`),
        error,
      });
    }
  };

  const updateGroup = async (
    groupId: string,
    name: string,
    userIds: string[],
  ) => {
    try {
      await httpClient.put(`/community/${communityId}/group/${groupId}`, {
        name,
        userIds,
      });
      patchCommunityDetails((c) => {
        return {
          ...c,
          groups: c.groups.map((g) => {
            if (g.id !== groupId) return g;
            return { ...g, name, userIds };
          }),
        };
      });
      return true;
    } catch (error) {
      reportServerError({
        title: _(msg`Failed to create group`),
        error,
      });
    }
    return false;
  };

  async function deleteCommunity() {
    if (!communityId) return;
    try {
      await httpClient.delete(`/community/${communityId}?markDeleted=true`);
      setState((s) => {
        const { [communityId]: toRemove, ...communities } = s.communities;
        return { ...s, communities };
      });
      communityCache.removeCommunity(communityId);
      return true;
    } catch (error: any) {
      reportServerError({
        title: _(msg`Failed to delete community`),
        error,
      });
    }
    return false;
  }

  async function archiveCommunity() {
    try {
      await httpClient.delete(`/community/${communityId}`);
      patchCommunityDetails((c) => {
        return { ...c, deactivatedAt: +new Date() };
      });
      return true;
    } catch (error: any) {
      reportServerError({
        title: _(msg`Failed to archive community`),
        error,
      });
    }
    return false;
  }

  async function restoreArchivedCommunity() {
    try {
      const { data } = await httpClient.post(
        `/community/${communityId}/restore`,
      );
      patchCommunityDetails(() => data);
      return true;
    } catch (error: any) {
      reportServerError({
        title: _(msg`Failed to restore community`),
        error,
      });
    }
    return false;
  }

  async function reportCommunity() {
    try {
      await httpClient.post(`/community/${communityId}/report`);
      return true;
    } catch (error) {
      reportServerError({
        title: _(msg`Failed to report community`),
        error,
      });
    }
    return false;
  }

  async function leaveCommunity() {
    try {
      await httpClient.delete(`/community/${communityId}/membership`);
      return true;
    } catch (error) {
      reportServerError({
        title: _(msg`Failed to leave community`),
        error,
      });
    }
    return false;
  }

  const community = state.communities[communityKey]?.community;

  const canHostSpaces = useMemo(() => {
    return CMR.isHost(community?.roles ?? []);
  }, [community]);

  const canInviteCollaborators = useMemo(() => {
    if (community?.corridorType === 'EDUCATIONAL' && canHostSpaces) return true;
    return community?.collabAllowed;
  }, [community, canHostSpaces]);

  const canChangeAudioSettings = useMemo(() => {
    return (
      community?.audioAllowed ||
      (community?.corridorType === 'EDUCATIONAL' && canHostSpaces)
    );
  }, [community]);

  const canChangeVideoSettings = useMemo(() => {
    return (
      community?.videoAccessApproved &&
      (community?.videoAllowed ||
        (community?.corridorType === 'EDUCATIONAL' && canHostSpaces))
    );
  }, [community]);

  const canCrossGroupCollab = useMemo(() => {
    if (community?.corridorType === 'EDUCATIONAL' && canHostSpaces) return true;
    return community?.crossGroupCollabAllowed;
  }, [community]);

  return {
    community,
    syncState: state.communities[communityKey]?.syncState ?? 'pending',
    patchCommunityDetails,
    loadCommunityDetails,
    setInviteCode,
    markMemberApproved,
    markAllMembersApproved,
    removeMember,
    changeRoles,
    changeInvitationRole,
    removeEmail,
    removeEmailDomain,
    updateCommunityBanner,
    createGroup,
    deleteGroup,
    updateGroup,
    leaveCommunity,
    reportCommunity,
    archiveCommunity,
    deleteCommunity,
    restoreArchivedCommunity,
    updateCommunity,
    canHostSpaces,
    canInviteCollaborators,
    canChangeAudioSettings,
    canChangeVideoSettings,
    canCrossGroupCollab,
  };
};
