import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useCommunitySummaries } from 'atoms/community-summaries';
import { useUserProfile } from 'atoms/user-profile';
import { httpClient } from '@cocoplatform/coco-rtc-client';
import ControlLabel from 'components/control-label/ControlLabel';
import FlexRow from 'components/flex/FlexRow';
import ControlledTextField from 'components/forms/ControlledTextField';
import MutliEmailInput, {
  sendInvites,
} from 'components/multi-email-input/MultiEmailInput';
import {
  COMMUNITY_MEMBERSHIP_ROLES,
  getSameOrLowerRoles,
} from 'constants/community-roles';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { EMAIL_REGEX } from 'utils/validation';
import { Trans } from '@lingui/macro';
import { useTutorial } from 'components/onboarding-tutorials/tutorial-atom';
import { useCommunityDetails } from 'atoms/community-details';
import { useModal } from 'mui-modal-provider';
import { EVENTS, INTERACTIONS, useTrack } from 'utils/mixpanel';

export function EducationRequestModal({ onClose }: { onClose: () => void }) {
  const theme = useTheme();

  const track = useTrack();

  useEffect(() => {
    track(EVENTS.EDUCATIONAL_REQUEST_ACCESS_MODAL, INTERACTIONS.SYSTEM);
  }, []);
  return (
    <Dialog open>
      <DialogContent
        sx={{
          paddingY: '2rem',
        }}
      >
        <Typography variant='h3' color={theme.palette.error.light}>
          <Trans>Please note</Trans>:
        </Typography>
        <Typography variant='body1'>
          <ul>
            <li>
              <Trans>
                Your request for education account will be processed soon.
              </Trans>
            </li>
            <li>
              <Trans>
                Some of the community features will show disabled until then.
              </Trans>
            </li>
            <li>
              <Trans>Currently, you can invite members through emails.</Trans>
            </li>
            <li>
              <Trans>
                Once approved, you will be able to invite students easily by
                creating unique login keys for them.
              </Trans>
            </li>
          </ul>
        </Typography>
        <Stack alignItems='center'>
          <Button
            data-analytics={EVENTS.EDUCATIONAL_REQUEST_OKAY}
            variant='contained'
            color='primary'
            onClick={() => onClose()}
          >
            <Trans>Okay</Trans>
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default function CommunityInviteForm(p: { showRequest: boolean }) {
  const [role, setRole] = useState<string>('MEMBER');

  const { showModal } = useModal();
  const [skipConfirmModalOpen, setSkipConfirmModalOpen] = useState(false);

  const newEmailTextFieldRef = useRef<HTMLInputElement>(null);

  const [dispatching, setDispatching] = useState(false);
  const [emails, setEmails] = useState<string[]>([]);

  const { communities } = useCommunitySummaries();

  const theme = useTheme();
  const { fetchUserProfile } = useUserProfile();

  const selectedRole = useMemo(() => {
    if (!role) return null;
    return COMMUNITY_MEMBERSHIP_ROLES.find((it) => it.value === role);
  }, [role]);

  const history = useHistory();

  const { startGettingStartedTutorial } = useTutorial();

  const { loadCommunityDetails } = useCommunityDetails({
    communityId: communities[0].id,
    autoLoad: false,
  });

  useEffect(() => {
    if (p.showRequest) {
      const modal = showModal(EducationRequestModal, {
        onClose: () => {
          modal.destroy();
        },
      });
    }
  }, [p.showRequest]);

  return (
    <>
      <Dialog open={skipConfirmModalOpen}>
        <DialogContent
          sx={{
            paddingY: '2rem',
          }}
        >
          <Typography variant='h2'>
            <Trans>Skip without inviting?</Trans>
          </Typography>
          <Typography mt={3} variant='body1'>
            <Trans>
              We recommend inviting others to your community so you can
              experience the power and joy of{' '}
              <strong>being. creative. together.</strong>
            </Trans>
          </Typography>
          <Stack
            mt={2}
            width='100%'
            justifyContent='end'
            spacing={2}
            direction='row'
          >
            <Button
              variant='outlined'
              color='primary'
              onClick={async () => {
                await fetchUserProfile();
                await loadCommunityDetails();
                history.push(`/app/dashboard/${communities[0].id}`);
                startGettingStartedTutorial();
              }}
            >
              <Trans>Skip step</Trans>
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setSkipConfirmModalOpen(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
      <Stack width='100%'>
        <MutliEmailInput
          emails={emails}
          setEmails={setEmails}
          setRoles={setRole}
        />
        <LoadingButton
          variant='contained'
          color='primary'
          disabled={emails.length === 0}
          onClick={async () => {
            setDispatching(true);
            await sendInvites(communities[0].id, emails, [role || 'MEMBER']);
            await fetchUserProfile();
            await loadCommunityDetails();
            history.push(`/app/dashboard/${communities[0].id}`);
            setDispatching(false);
            setEmails([]);
            startGettingStartedTutorial();
          }}
          loading={dispatching}
        >
          <strong>
            <Trans>Send invites</Trans>
          </strong>
        </LoadingButton>
        <Box mt={2} />
        <Button
          variant='outlined'
          onClick={async () => {
            setSkipConfirmModalOpen(true);
          }}
        >
          <strong>
            <Trans>Skip for now</Trans>
          </strong>
        </Button>
      </Stack>
    </>
  );
}
