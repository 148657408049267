import Dialog, { DialogProps } from '@mui/material/Dialog';
import React from 'react';
import cc from 'classcat';
import C from './CloseableDialog.css';
import { Trans } from '@lingui/macro';
import { Box } from '@mui/material';

export type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

const CloseableDialog = ({
  onClose,
  className,
  children,
  ...p
}: Without<DialogProps, 'open'> & {
  onClose?: () => void;
  children: React.ReactChild | React.ReactChild[];
  className?: string;
}) => (
  <Dialog
    PaperProps={{
      sx: {
        boxShadow: 'none',
      },
    }}
    open
    onClose={onClose}
    className={cc([C.dialog, className])}
    {...p}
  >
    <a className={C.closeLink} onClick={onClose}>
      <Trans>Close</Trans>
    </a>
    <Box sx={{ backgroundColor: 'white', borderRadius: '8px' }}>{children}</Box>
  </Dialog>
);

export default CloseableDialog;
